import React from "react";
import Faqs from "../components/Faqs";

import { Helmet } from "react-helmet";

const FAQS = (props) => {
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions About Hauzd / 3D Presentations | hauzd</title>
        <meta
          name="description"
          content="Find out most frequently asked questions about Hauzd Basics / 3D Presentations / Choosing your Plan / Hardware requirements and request a demo."
        />
      </Helmet>
      <Faqs />
    </>
  );
};

export default FAQS;
