import React from "react";
import { Link } from "react-router-dom";

import footerLogo from "../assets/images/footer-logo.png";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-bg">
        <div className="container">
          <div className="footer-logo text-center">
            <img src={footerLogo} />
          </div>
          <h3 className="text-center">Boost your Sales Process</h3>
          <div className="text-center">
            <a
              href="https://APP.HAUZD.COM/realestate"
              target="_blank"
              className="text-center get-started"
            >
              GET STARTED NOW
            </a>
          </div>
          <div className="row footer-menu">
            <div className="col-md-4 col-sm-6 contact-details">
              <h6>Contact</h6>
              <div className="row">
                <div className="col-sm-6">
                  <p className="address-size">
                    San Francisco <br />
                    18 Bartol Street #192 San Francisco, CA 94133 <br />
                    +1 415 800 4357
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="address-size">
                    Panama
                    <br />
                    City of Knowledge,
                    <br />
                    Bldg 109.
                    <br />
                    Panama City
                    <br />
                    +507 310-0361
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-6">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-6 text-center">
                  <h6>About</h6>
                  <ul>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                    <li>
                      <a href="https://blog.hauzd.com/">Blog</a>
                    </li>
                    <li>
                      <a href="https://app.hauzd.com/integrations/">Integrations</a>
                    </li>
                    <li>
                      <Link to="/terms">Terms</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-6 text-center">
                  <h6>Support</h6>
                  <ul>
                    <li>
                      <a href="https://learn.hauzd.com/">Help center</a>
                    </li>
                    <li>
                      <Link to="/cookies">Cookies policy</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-6 text-center">
                  <h6>Resources</h6>
                  <ul>
                    <li>
                      <a href="https://hauzd.com/sitemap.xml">Site map</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6 col-6 text-center">
                  <h6>Account</h6>
                  <ul>
                    <li>
                      <a href="https://admin.hauzd.com/login/">Login</a>
                    </li>
                    <li>
                      <a href="https://app.hauzd.com/realestate">Sign up</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row social-links">
            <div className="col-md-12">
              <a href="https://www.facebook.com/Hauzd" target="_blank">
                <i className="fa fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/hauzd/" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/hauzd/" target="_blank">
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
          <p className="copyright-text">
            hauzd &copy; 2022. All rights reserved. Terms, features and pricing
            subject to change without notice.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
