import React from "react";
import iphone from "../assets/images/homepage/i-phone-copy/i-phone-copy.png";
import boostIcon from "../assets/images/homepage/general-icons/general-icons-boost.png";
import shareIcon from "../assets/images/homepage/general-icons/general-icons-share.png";
import interactionIcon from "../assets/images/homepage/general-icons/general-icon-client-interaction.png";
import marketingIcon from "../assets/images/homepage/general-icons/general-icons-marketing.png";

const WhyHauzd = () => (
  <section className="scroll-next-section why-hauzd">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="intro-image">
            <img src={iphone} className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="intro-cards">
            <div className="row">
              <div className="col-md-6">
                <h2 className="section-title">Why Hauzd?</h2>
                <div className="intro-card left-align">
                  <div className="general-icon blue-icon">
                    <img src={boostIcon} />
                  </div>
                  <h3 className="card-title">Boost your sales process</h3>
                  <p className="card-content">
                    Shorten your sales cycle by 25%.
                  </p>
                </div>
                <div className="intro-card right-align mt-30">
                  <div className="general-icon aqua-icon">
                    <img src={shareIcon} />
                  </div>
                  <h3 className="card-title">Centralize your marketing</h3>
                  <p className="card-content">
                    All information in one place and up to date.
                  </p>
                </div>
                <div className="clear"></div>
              </div>
              <div className="col-md-6">
                <div className="intro-card left-align mt-md-30">
                  <div className="general-icon yellow-icon">
                    <img src={interactionIcon} />
                  </div>
                  <h3 className="card-title">Client Interaction</h3>
                  <p className="card-content">
                    Engage your clients by bringing them a 3D user experience.
                  </p>
                </div>
                <div className="intro-card right-align mt-30">
                  <div className="general-icon pink-icon">
                    <img src={marketingIcon} />
                  </div>
                  <h3 className="card-title">Share and engage</h3>
                  <p className="card-content">
                    Hauzd presentation allow buyers to explore houses and
                    offices for sale or rent from their home, a fair or a
                    showroom on a big touchscreen.
                  </p>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhyHauzd;
