import React from "react";
import { connect } from "react-redux";

import Project from "../components/Project";

import { fetchGallery } from "../actions/gallery";

import { Helmet } from "react-helmet";

class Gallery extends React.Component {
  componentDidMount() {
    if (!this.props.gallery.length > 0) {
      this.props.fetchGallery();
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Explore Real Estate 3D Virtual Tour Gallery: Residential, Commercial, Mixed-use projects | hauzd
          </title>
          <meta
            name="description"
            content="Explore real estate 3D virtual tours gallery of Residential, Commercial, and Mixed-use projects all over the world. Boost your Sales Process using hauzd."
          />
        </Helmet>
        <section className="hauzd-gallery">
          <div className="container">
            <div className="main-titel">
              <h1>Hauzd 3D Gallery Presentations</h1>
              <p>Explore real estate 3D presentations all over the world</p>
            </div>
            <div className="row">
              {this.props.gallery &&
                this.props.gallery
                  .sort((a, b) =>
                    a.website_feature < b.website_feature ? 1 : -1
                  )
                  .map((project, index) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 mt-4"
                      key={index}
                    >
                      <Project project={project} />
                    </div>
                  ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gallery: state.gallery.gallery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGallery: () => dispatch(fetchGallery()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
