import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Archviz from "./containers/Archviz";
import Gallery from "./containers/Gallery";
import Pricing from "./containers/Pricing";
import PricingArchviz from "./containers/PricingArchviz";
import AboutUs from "./containers/AboutUs";
import ContactUs from "./containers/ContactUs";
import Terms from "./containers/Terms";
import Cookies from "./containers/Cookies";
import FAQS from "./containers/FAQS";
import CalendlyContainer from "./containers/CalendlyContainer";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/faqs" component={FAQS} />

        <Route
          exact
          path="/book-a-call"
          component={() => <CalendlyContainer type="book-a-call" />}
        />
        <Route
          exact
          path="/val"
          component={() => <CalendlyContainer type="val" />}
        />
        <Route
          exact
          path="/marilin"
          component={() => <CalendlyContainer type="marilin" />}
        />
        <Route
          exact
          path="/dan"
          component={() => <CalendlyContainer type="dan" />}
        />
        <Route
          exact
          path="/alberto"
          component={() => <CalendlyContainer type="alberto" />}
        />
        <Route
          exact
          path="/mario"
          component={() => <CalendlyContainer type="mario" />}
        />
        <Route
          exact
          path="/laura"
          component={() => <CalendlyContainer type="laura" />}
        />
        <Route
          exact
          path="/moira"
          component={() => <CalendlyContainer type="moira" />}
        />
        <Route
          exact
          path="/esteban"
          component={() => <CalendlyContainer type="esteban" />}
        />
        <Route
          exact
          path="/viviana"
          component={() => <CalendlyContainer type="viviana" />}
        />
        <Route
          exact
          path="/success"
          component={() => <CalendlyContainer type="success" />}
        />
        <Route
          exact
          path="/fernanda"
          component={() => <CalendlyContainer type="fernanda" />}
        />
        <Route
          exact
          path="/richard"
          component={() => <CalendlyContainer type="richard" />}
        />
        <Route
          exact
          path="/jessica"
          component={() => <CalendlyContainer type="jessica" />}
        />
        <Route
          exact
          path="/giorselys"
          component={() => <CalendlyContainer type="giorselys" />}
        />
        <Route
          exact
          path="/ana"
          component={() => <CalendlyContainer type="ana" />}
        />
        <Route
          exact
          path="/gustavo"
          component={() => <CalendlyContainer type="gustavo" />}
        />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
