import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const Testimonials3D = () => {
  const testimonials = [
    {
      name: "Rodrigo Ramos",
      company: "TIF Digital",
      image: "tif-digital.png",
      video: "eV-Stehwl1k",
      review:
        "It took us 4 weeks to create our first 3D App and when we packaged the final version, we received a link that works for all platforms. If I compare hauzd with game engines like Unreal or Unity, there’s a huge difference in the production time and costs.",
    },
    {
      name: "Alvaro Latarowski",
      company: "Snapping",
      video: "",
      image: "snapping.png",
      review:
        "With hauzd, We are not just selling renderings and videos or 360 images anymore, but also a complete sales solution that is attractive for our customers and adds great value to our services. We are creating innovative solutions without getting into expensive software development ourselves!",
    },
    {
      name: "Diego Sefercheoglou",
      company: "Craft CG",
      video: "",
      image: "craftcg.png",
      review:
        "What I really like about hauzd engine is that we don’t need to hire any highly skilled 3D programmers to create an interactive solution for our real estate clients. We only focus on 3d modelling with the software that we already have expertise in like 3ds Max.",
    },
  ];

  const [isOpenFirstVideo, setIsOpenFirstVideo] = useState(false);
  const [isOpenSecondVideo, setIsOpenSecondVideo] = useState(false);
  const [isOpenThirdVideo, setIsOpenThirdVideo] = useState(false);

  return (
    <section className="testimonials bg-quote">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="page-title">Our Success Stories</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[0].review}</p>
              <ModalVideo
                channel="youtube"
                isOpen={isOpenFirstVideo}
                videoId={testimonials[0].video}
                onClose={() => setIsOpenFirstVideo(false)}
              />
              <a
                onClick={() => setIsOpenFirstVideo(true)}
                className="card-link"
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[0].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[0].name}</h4>
                  <p className="organisation">{testimonials[0].company}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[1].review}</p>
              <a
                href="javascript:void(0);"
                className="card-link"
                style={{ visibility: "hidden" }}
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[1].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[1].name}</h4>
                  <p className="organisation">{testimonials[1].company}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[2].review}</p>
              <a
                href="javascript:void(0);"
                className="card-link"
                style={{ visibility: "hidden" }}
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[2].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[2].name}</h4>
                  <p className="organisation">{testimonials[2].company}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials3D;
