import { SET_PROJECTS } from "../constants/ActionTypes";

const initialState = {
  projects: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    default:
      return state;
  }
}
