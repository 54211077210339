import React from "react";

const Faqs = () => {
  const faqs = [
    {
      title: "Hauzd Basics",
      questions: [
        {
          title: "What is Hauzd Engine?",
          body:
            "Hauzd has developed it’s own 3D engine. Designed and optimized for architectural visualization, Hauzd is 3x times faster than game engines. Hauzd enables architectural visualization studios to easily create, publish and share Interactive 3D Presentations for Real Estate. With a Hauzd subscription, you get access to the software and tools. Check out the main features here You can still experience 3D Presentations powered by Hauzd tech on web or mobile without a subscription: Our public gallery of 3D projects (click on the link to open each presentation).",
        },
        {
          title: "What is Admin Panel?",
          body:
            "The cloud-based Admin Panel is the site where you can manage projects, update pricing/availability of units, connect with CRM, visualize statistics and more!",
        },
        {
          title:
            "How do I get a 3D Presentation? What if I don’t want to create it myself?",
          body:
            "There are two ways that anyone can start creating 3D presentations with Hauzd Engine: 1) If you are an expert in architectural visualization, you can either buy a Subscription for a specific project, create and manage your own 3D content (a great solution if you’re already servicing Real estate clients or want to provide 3D Presentations as a service) 2) You can hire a Hauzd Partner to make Presentations for you. We do not set pricing for our Partners. They typically charge based on their local market, project size, complexity, number of units, types of floorplans… Fill out this form to get a customized quote for your project.",
        },
        {
          title:
            "I want to become a Hauzd Partner and offer 3D interactive presentations to my real estate clients…Where should I start?",
          body:
            "Sign Up for a Free Trial at Hauzd.com You will receive an E-mail to confirm your account and then will have access to download Hauzd Engine and start creating your first project. Also you can schedule a call with our team to help you right away.",
        },
        {
          title:
            "What happens when you release new features? It requires to upgrade?",
          body:
            "We are always working on new innovations to help our customers get greater value from our software. By offering a SaaS (software as a service) solution, we can make frequent updates to the code that benefits all of our customers, with little customer action at all. Hauzd can make new features and bug fixes available to everyone without having customers go through an upgrade process. New features and functionality are frequently backwards-compatible with 3D Presentations that you have already created.",
        },
        {
          title:
            "Where I can find marketing resources such as sales presentations about Hauzd technology?",
          body:
            "At Hauzd, we provide you with guidance to learn and succeed. Check out our Resources site where you can find guides, solution briefs, case studies, ebooks and more!",
        },
      ],
    },
    {
      title: "3D Presentations",
      questions: [
        {
          title:
            "Does creating a 3D Presentation with Hauzd Engine require software development?",
          body:
            "There is no software development involved, instead you will focus on 3D modelling (low poly) with 3DS MAX. Hauzd Engine exports/imports your entire scenes from 3DS Max and your UI design from AI files in two clicks. Additionally, you can control texts for labels and names that appear in the 3D Presentation in Real Time on our Admin Panel.",
        },
        {
          title:
            "Do I need to create the 3D scenes and send them to Hauzd Team?",
          body:
            "Definitely not. Hauzd has set you up with all of the tools and you will be able to do everything by yourself from the start: 3D content production + UI customization + project setup + publishing to all platforms.",
        },
        {
          title:
            "How much time does it take to create a 3D interactive presentation?",
          body:
            "It depends on the complexity and size, but your first project (small, medium) could take up to 2 months to create it with a full time 3D artist working on it but for a medium project with one tower and 10 types of floorplans, it should only take about 5 weeks (from the start till the end).",
        },
        {
          title: "Which type of real estate projects are supported?",
          body:
            "Hauzd supports any type of Real Estate projects: Residential, Commercial, mixed-use for sale or rent.",
        },
        {
          title: "What kind of file formats does Hauzd support?",
          body:
            "For now, Hauzd only supports 3DS Max files (fbx). We are working on new software compatibilities.",
        },
        {
          title: "How many triangles can I have?",
          body:
            "Your project will be split up into separate scenes: Masterplan, Exterior, and Floorplan and the recommended number of triangles per scene is 200.000 approximately. There is no limit on how many scenes you can have in your project. Our software will dynamically manage loading/unloading of scenes in your presentation.",
        },
        {
          title: "Can I preview my 3D scenes?",
          body:
            "Yes, Hauzd engine comes with scene viewer where you can preview your scenes. You can also launch the test version of your project to see your scene inside the app right after importing.",
        },
        {
          title: "Is the scene's illumination baked within Hauzd Engine?",
          body: "Yes, Hauzd Engine allows you to bake all your scenes.",
        },
        {
          title:
            "Do I have to create the shaders/materials within Hauzd Engine?",
          body:
            "Hauzd Engine imports materials along with all textures directly from 3DS Max.",
        },
        {
          title: "Which kind of textures can I use for my projects?",
          body:
            "Hauzd Engine supports JPG, PNG file formats with/without transparency. Textures are square, power of two and up to 2048 by 2048 pixels. Hauzd Engine will take care of all the hardware specific texture compression automatically.",
        },
        {
          title: "Is it possible to customize the UI (user-interface) ?",
          body:
            "Yes, UI is specified by Adobe Illustrator files where you can change everything: colors, buttons, fonts… It takes two clicks to import your design and you can launch the test version of your app to see your changes. Also, we provide a ready to go standard UI that you can use for all your projects.",
        },
        {
          title: "Does Hauzd provides a low poly library?",
          body:
            "Yes, a full library of furniture, vegetation, cars and people for you to start with.",
        },
        {
          title: "How many 3D Artists can work in the same project?",
          body:
            "As many as you want. Hauzd doesn’t charge per user. Your team can work in the same project in different PCs in parallel, for example one with Exteriors scenes, the other with Interiors scenes.",
        },
        {
          title:
            "How long before the project is published after the work is finished?",
          body:
            "After you upload the final project package to the Hauzd cloud, your app will go live in 1-2 hrs* (depends on the size of the project)",
        },
        {
          title: "How large are final project downloads?",
          body:
            "Our final packages are usually only 50-100 Mb in size. Web version is able to stream the content and installed apps store the content locally so that internet connection is not needed to run the projects.",
        },
        {
          title:
            "Is there a maximum of downloads for each project presentation?",
          body:
            "Unlimited downloads are allowed from everywhere. Hauzd doesn’t charge per project download.",
        },
        {
          title:
            "Can I decide which types of features my Presentation will have?",
          body:
            "For sure. It’s up to you! For i.e.: some clients would just want to filter by price and some others by SQM only. It depends on each project/client needs.",
        },
        {
          title:
            "Is there any place where I can find tutorials or documentation in order to learn how to create a 3D presentation with Hauzd engine?",
          body:
            "All our video tutorials, documentation and Community can be find at Help Desk. It’s FREE! Also, we provide support trough Chat Online, Videocalls and Email.",
        },
        {
          title:
            "How do I open a 3D Presentation? Do I need Internet connection?",
          body:
            "Nope. Click on the project link and it will ask you to Download Hauzd App from the Apple Store or Google Play. Once Hauzd App is downloaded, it will automatically install the project on your device and afterwards you won’t need internet to run it, just open your Hauzd App and the project will be there. IMPORTANT! If there’s any update on the project (3D content or unit price/availability), you will need internet connection to get all those updates automatically on your device.",
        },
        {
          title:
            "How is inventory pricing and availability controlled by users?",
          body:
            "Your real estate client will get a login to a simple web-based admin panel where they can change the status and pricing information of all of the units and will be propagated to all installed apps within seconds. You can also hook up a CRM connection to drive the status and pricing automatically.",
        },
        {
          title:
            "As a Real Estate Developer or Broker…How Should I Market or communicate my Presentation?",
          body:
            "It all depends on your marketing and communication strategy. Some Real Estate Developers use the 3D Presentation internally for their Sales Teams/Brokers in Showrooms/Expos. Some others share the link on social media or any other portals to attract more buyers. Also, we’ve seen developers embedding the 3D presentation on their website.",
        },
      ],
    },
    {
      title: "Choosing your Plan",
      questions: [
        {
          title:
            "What is the pricing for a 3D Architectural Visualization studio?",
          body:
            "Hauzd will only charge you for the software (Engine) and you will create the whole 3D presentation for your client (2D, 3D models, setup features, configuration, UI…) Hauzd subscription fees include all software and hosting PER PROJECT (one project means one subscription fee)",
        },
        {
          title: "What are the differences between each Plan?",
          body:
            "Hauzd offers different plans for different types and projects depending on the number of units*: Small, Medium, Large. *One unit is considered one apartment, house, floorplan, amenity, tower… You can take a look at our updated Pricing here",
        },
        {
          title:
            "How Hauzd Partners should quote a project for a real estate client?",
          body:
            "Hauzd Partners are third-party companies who independently charge their own rates based on their local markets. Fill out this form to put you in contact with them! They will send you a customized quote for the production of each project. Each project and market is different. Most of our Partners charge their clients using these measurements: Content creation for the whole project* (3d models, floorplans, UI, …) + Hauzd Subscription fee for that project ",
        },
        {
          title:
            "Which kind of information do I need from my client to send a customized quotation for the project production?",
          body:
            "In order to quote a project, you should ask your client some basic information: Brochures, Description, Renderings and CAD files… You need to know how many towers, types of units, amenities, floorplans to calculate the 3D production timeline and your costs. Check out our Resources here",
        },
        {
          title: "How many users can work with one subscription fee?",
          body:
            "Hauzd doesn’t charge per user. You can start the work (3D modeling) with 2 different Artists at different PCs working in parallel with one project subscription paid.  Once you decide to PUBLISH the final version of your project, the subscription timeline will start counting from that date. It means you can start the work now and publish later.  ",
        },
        {
          title:
            "How long Hauzd is going to charge me the “Subscription fee” per project?",
          body:
            "You are required to pay a minimum period of 1 year upfront. After the first year the fee is halved and you can stop and cancel project’s subscription at any time.",
        },
        {
          title: "Is there any specific Hauzd services contract?",
          body:
            "Once you get started, you will access our Admin control panel. By accepting our terms and conditions, it means that you agree our contract services. ",
        },
      ],
    },
    {
      title: "Hardware requirements",
      subtitle:
        "Hauzd Engine is optimized to run on a large variety of devices.",
      questions: [
        {
          title: "Windows PC devices: OS 10, 11",
          body:
            "Laptops and desktops dedicated graphics card recommended. Intel HD 630 graphics card minimum.",
        },
        {
          title: "Big touch screens",
          body:
            "Capacitive Touch screen (infrared not recommended) Mini PC all in one: Intel HD 630 graphics card minimum.",
        },
        {
          title: "Android mobile devices: OS 5.0+",
          body:
            "Low-mid level phones and tablets: Snapdragon, Exynos, Mediatek.",
        },
        {
          title: "iOS mobile devices",
          body: "iPhone 5s or later, iPad Air (1st gen) or later.",
        },
        {
          title: "MacOS devices",
          body: "iMac 2013 and newer macOS devices can access any project using internet browser."
        },
      ],
    },
  ];

  function handleQuestion(event) {
    const element = event.target;
    if (
      element.classList.contains("question_button") ||
      element.classList.contains("question_title")
    ) {
      const body = event.currentTarget.querySelector(".question_body");
      const button = event.currentTarget.querySelector(".question_button");
      body.classList.toggle("question_body--active");
      button.classList.toggle("question_button--active");
      console.log("si llega al evento");
    }
  }

  return (
    <section className="testimonials pt-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section cookies">
              <h1 className="card-title">Frequently Asked Questions</h1>
              <h3>
                Find our most common questions below. We are happy to help you.
              </h3>

              {faqs.map((faq, inx) => {
                return (
                  <div className="faq_container" key={inx}>
                    <h1>{faq.title}</h1>
                    <h3>{faq.subtitle}</h3>
                    <ul className="list-unstyled">
                      {faq.questions.map((question, indx) => {
                        return (
                          <li onClick={handleQuestion} key={indx}>
                            <div>
                              <button className="question_button">
                                <img
                                  src={require("../assets/images/plus.svg")}
                                  alt=""
                                />
                              </button>
                              <h4 className="question_title">
                                {question.title}
                              </h4>
                            </div>
                            <p className="question_body">{question.body}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
