import React, { useEffect } from "react";

export default function Calendly(props) {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  /* 

      {props.type == "book-a-support-call" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000000135854' frameborder='0' allowfullscreen='' > </iframe>
      )}

  */

  return (
    <>
      {props.type == "book-a-call" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/dan' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "marilin" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000000255014' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "val" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000000715002' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "dan" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000000135856' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "alberto" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000001082220' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "mario" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000001437044' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "laura" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000001587484' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "moira" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000001326006' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "esteban" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000002004038' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "viviana" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000003060036' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "success" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000003532004' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "richard" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000004169074' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "jessica" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000004334014' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "giorselys" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000004334062' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "ana" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000005685002' frameborder='0' allowfullscreen='' > </iframe>
      )}

      {props.type == "gustavo" && (
        <iframe width='100%' height='750px' src='https://hauzd.zohobookings.com/portal-embed#/customer/4047964000007031160' frameborder='0' allowfullscreen='' > </iframe>
      )}
    </>
  );
}
