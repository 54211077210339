import React from "react";
import Banner from "../components/Banner";
import Counters from "../components/Counters";
import Integrations from "../components/Integrations";
import Testimonials from "../components/Testimonials";
import WhyHauzd from "../components/Whyhauzd";
import Logos from "../components/Logos";
import Features from "../components/Features";

import { Helmet } from "react-helmet";

class HomepageContainer extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            The Ultimate 3D Sales Solution and Virtual Tour for Real Estate | Hauzd
          </title>
          <meta
            name="description"
            content="Boost your sales process with a 3D Real estate tool. Centralize all your marketing materials in one place and engage home buyers by offering an amazing 3D user experience powered by hauzd technology."
          />
        </Helmet>
        <Banner />
        <WhyHauzd />
        <Features />
        <Counters />
        <Integrations />
        <Testimonials />
        <Logos />
        <a
          style={{ display: "none" }}
          href="https://app.hauzd.com/optin_box/rfarno37refmzh6l"
          data-delay="20000"
          data-exit="false"
        >
          <img src="https://images.clickfunnels.com/8b/1d04d42e1a414286019f8f399864a5/Success_story_Dovle.png" />
        </a>
        <script
          src="https://www.clickfunnels.com/assets/cf_modal.js"
          type="text/javascript"
        ></script>
      </>
    );
  }
}

export default HomepageContainer;
