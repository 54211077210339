import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import ThreeDImageOne from "../assets/images/fade-images/3d-interior-1.png";
import ThreeDImageTwo from "../assets/images/fade-images/3d-interior-2.png";
import ThreeDImageThree from "../assets/images/fade-images/3d-interior-3.png";
import ThreeDImageFour from "../assets/images/fade-images/3d-interior-4.png";

import projectLocationOne from "../assets/images/fade-images/project-location-1.png";
import projectLocationTwo from "../assets/images/fade-images/project-location-2.png";

import arIcon from "../assets/images/homepage/general-icons/general-icons-vr.png";
import arThumbnail from "../assets/images/video-thumbnails/ar.png";
import threesixtyIcon from "../assets/images/homepage/general-icons/general-icons-360.png";
import threesixtyThumbnail from "../assets/images/video-thumbnails/360.png";

import renderingOne from "../assets/images/fade-images/rendering-1.jpg";
import renderingTwo from "../assets/images/fade-images/rendering-2.png";

import priceList from "../assets/images/fade-images/price-list.jpg";

import touchScreenIcon from "../assets/images/homepage/general-icons/general-icons-touch-screen.png";
import customizedUiIcon from "../assets/images/homepage/general-icons/general-icons-customized-ui.png";
import unlimitedDownloadsIcon from "../assets/images/homepage/general-icons/general-icons-unlimited-downloads.png";

import moduleImageOne from "../assets/images/fade-images/statistics_modules_1.jpg";
import moduleImageTwo from "../assets/images/fade-images/statistics_modules_2.jpg";
import moduleImageThree from "../assets/images/fade-images/statistics_modules_3.jpg";
import moduleImageFour from "../assets/images/fade-images/statistics_modules_4.jpg";

import adminPanelOne from "../assets/images/fade-images/admin_panel_1.jpg";
import adminPanelTwo from "../assets/images/fade-images/admin_panel_2.jpg";

const Features = () => {
  const [isOpen360Video, setIsOpen360Video] = useState(false);
  const [isOpenARVideo, setIsOpenARVideo] = useState(false);
  return (
    <>
      <section className="features">
        <span className="bg-gradient bg-rotate-345"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info-card">
                <div className="card-thumb-image cross-fade-card-images-4">
                  <img src={ThreeDImageOne} className="img-fluid" />
                  <img src={ThreeDImageTwo} className="img-fluid" />
                  <img src={ThreeDImageThree} className="img-fluid" />
                  <img src={ThreeDImageFour} className="img-fluid" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">
                    3D exterior and interior spaces
                  </h3>
                  <p className="card-description">
                    Explore entire building with all floorplans in incredible
                    live 2D/3D.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-card mt-45">
                <div className="card-thumb-image cross-fade-card-images-2">
                  <img src={projectLocationOne} className="img-fluid top" />
                  <img src={projectLocationTwo} className="img-fluid bottom" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">
                    Project location and points of interest
                  </h3>
                  <p className="card-description">
                    Check project location and points of interest like
                    hospitals, schools, airport, shopping malls...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-md-first">
              <div className="info-card-2 text-right vertical-align">
                <div className="card-icon">
                  <img src={arIcon} />
                </div>
                <h3 className="card-title">Augmented Reality</h3>
                <p className="card-description">
                  Project the property on the desk in front of users and make
                  the physical mockups a thing of the past.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-md-last">
              <div className="banner-thumbnail">
                <img src={arThumbnail} className="img-fluid" />
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpenARVideo}
                  videoId="8FjbUvrTjLg"
                  onClose={() => setIsOpenARVideo(false)}
                />
                <a
                  onClick={() => setIsOpenARVideo(true)}
                  href="javascript:void(0);"
                  className="banner-link popup-modal"
                >
                  See live demo
                  <span className="play-icon fa fa-play"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <span className="bg-gradient bg-rotate-15"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-sm-2 order-lg-1">
              <div className="banner-thumbnail">
                <img src={threesixtyThumbnail} className="img-fluid" />
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen360Video}
                  videoId="HJV16_4gyuU"
                  onClose={() => setIsOpen360Video(false)}
                />
                <a
                  onClick={() => setIsOpen360Video(true)}
                  href="javascript:void(0);"
                  className="banner-link popup-modal"
                >
                  See live demo
                  <span className="play-icon fa fa-play"></span>
                </a>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-sm-1 order-lg-2">
              <div className="info-card-2 vertical-align">
                <div className="card-icon">
                  <img src={threesixtyIcon} />
                </div>
                <h3 className="card-title">360&deg; views</h3>
                <p className="card-description">
                  Immerse your buyers in the space and let them fall in love
                  with your project as they navigate high definition 360 images
                  of interiors and exteriors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <span className="bg-gradient-2"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info-card">
                <div className="card-thumb-image cross-fade-card-images-2">
                  <img src={renderingOne} className="img-fluid top" />
                  <img src={renderingTwo} className="img-fluid bottom" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">Renderings and images</h3>
                  <p className="card-description">
                    Gallery images included: interior and exterior renderings...
                  </p>
                </div>
              </div>
              <span className="bg-shape-blue"></span>
            </div>
            <div className="col-lg-6">
              <span className="bg-shape-circle"></span>
              <div className="info-card mt-45">
                <div className="card-thumb-image">
                  <img src={priceList} className="img-fluid" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">Integration with CRMs</h3>
                  <p className="card-description">
                    Look at the unit that you want and see its price and
                    availability in real time, as well as specifications:
                    bedrooms, baths area... all the information in one simple
                    list, quick and easy to understand by your team and buyers.
                    Click on the unit and it will highlight it in the 3D scene.
                  </p>
                </div>
              </div>
              <span className="bg-shape-red"></span>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="feature-card">
                <div className="card-icon">
                  <img src={touchScreenIcon} />
                </div>
                <h3 className="card-title">Touch screens</h3>
                <p className="card-description">
                  Run it in big touch screens for showrooms, fairs and expos.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card">
                <div className="card-icon">
                  <img src={customizedUiIcon} />
                </div>
                <h3 className="card-title">Customized UI</h3>
                <p className="card-description">
                  Customize the look and feel of your project: colors,
                  background, fonts...
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card">
                <div className="card-icon">
                  <img src={unlimitedDownloadsIcon} />
                </div>
                <h3 className="card-title">Unlimited downloads</h3>
                <p className="card-description">
                  For buyers, brokers, sales staff to use in showrooms, expos
                  and to take home.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <a
                href="https://APP.HAUZD.COM/realestate"
                target="_blank"
                className="theme-button"
              >
                Request a Demo
                <i className="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <span className="bg-gradient"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info-card">
                <div className="card-thumb-image cross-fade-card-images-4">
                  <img src={moduleImageOne} className="img-fluid" />
                  <img src={moduleImageTwo} className="img-fluid" />
                  <img src={moduleImageThree} className="img-fluid" />
                  <img src={moduleImageFour} className="img-fluid" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">Google Analytics integration</h3>
                  <p className="card-description">
                    Do you know what is happening with your 3D app? Who are the
                    real users and where they are from? Such data is invaluable
                    to your decision making process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-card mt-45">
                <div className="card-thumb-image cross-fade-card-images-2">
                  <img src={adminPanelOne} className="img-fluid top" />
                  <img src={adminPanelTwo} className="img-fluid bottom" />
                </div>
                <div className="card-content">
                  <h3 className="card-title">
                    Manage your project via Admin Panel
                  </h3>
                  <p className="card-description">
                    Update unit prices and availability via control panel or
                    Connect your Live Excel and get automatic updates everyday.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
