import React from "react";
import Team from "../components/Team";

const Teams = (props) => {
  const teams = [
    {
      name: "Adriana Aguilera",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/adriana-aguilera-581770129/",
      img: "Adriana_Aguilera.jpg",
    },
    {
      name: "Alberto Morales",
      position: "Sales Manager",
      link: "https://www.linkedin.com/in/alberto-morales-16b4837b/",
      img: "Alberto_Morales.png",
    },
    {
      name: "Ana Carou",
      position: "Executive Assistant",
      link: "https://www.linkedin.com/in/ana-carolina-carou-b27ba71a/",
      img: "Ana_Carou.jpg",
    },
    {
      name: "Anahi Gandini",
      position: "Front-End Developer",
      link: "https://www.linkedin.com/in/anigandini/",
      img: "Anahi_Gandini.jpg",
    },
    {
      name: "Aury Morales",
      position: "Lead Asset Artist",
      link: "https://www.linkedin.com/in/arqammo/",
      img: "AURY_MORALES.jpg",
    },
    {
      name: "Camila Lopez",
      position: "Customer Success Manager",
      link: "https://www.linkedin.com/in/camila-ail%C3%A9n-l%C3%B3pez-abb01312b/",
      img: "Camila_Lopez.png",
    },
    {
      name: "Cristian Cibils",
      position: "Project Manager",
      link: "https://www.linkedin.com/in/cristiancibils/",
      img: "Cristian_Cibils.png",
    },
    {
      name: "Dan Burdman",
      position: "Co-founder",
      link: "https://www.linkedin.com/in/danburdman/",
      img: "dan.jpg",
    },
    {
      name: "Daniela Aguirre",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/daniela-aguirre-5b1745211/",
      img: "Daniela_Aguirre.jpg",
    },
    {
      name: "Eduardo Novoa",
      position: "Art QA Analyst",
      link: "https://www.linkedin.com/in/eduardo-novoa-rodr%C3%ADguez-595b2350/",
      img: "Eduardo_Novoa.jpg",
    },
    {
      name: "Emmanuel Bermudez",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/manuel-bermudez-9b8b9b225",
      img: "Emmanuel_Bermudez.png",
    },
    {
      name: "Esteban Castañeda",
      position: "Account Executive",
      link: "https://www.linkedin.com/in/esteban-alva-casta%C3%B1eda-089164146/",
      img: "ESTEBAN_CASTAÑEDA.png",
    },
    {
      name: "Fernanda Ramirez",
      position: "Lead Generator",
      link: "https://www.linkedin.com/in/fernanda-ramirez-491a4711b/",
      img: "Fernanda_Ramirez.jpg",
    },
    {
      name: "Fernando Gromaz",
      position: "3D Artist",
      link: "https://www.linkedin.com", //no lo encuentro...
      img: "Fernando_Gromaz.jpg",
    },
    {
      name: "Giorselys Toicen",
      position: "Business Dev. Manager",
      link: "https://www.linkedin.com/in/giorselystoicen/",
      img: "Giorselys_Toicen.png",
    },
    {
      name: "Jessica Gastélum",
      position: "Business Dev. Manager",
      link: "https://www.linkedin.com/in/jessica-gastelum-baez-91394857/",
      img: "Jessica_Gastelum.jpeg",
    },
    {
      name: "Gonzalo Hourcade",
      position: "Back-End Lead Developer",
      link: "https://www.linkedin.com/in/gonzalo-hourcade-b134a2115/",
      img: "GONZALO_HOURCADE.png",
    },
    {
      name: "Jhon Rincón",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/jhonr3d/",
      img: "Jhon_Rincon.jpg",
    },
    {
      name: "Laura Galvan",
      position: "Account Executive",
      link: "https://www.linkedin.com/in/laura-galv%C3%A1n-dom%C3%ADnguez-0a72301a2/",
      img: "Laura_Galvan.png",
    },
    {
      name: "Luisina Di Giacinti",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/luisina-digiacinti/",
      img: "Luisina_Giacinti.png",
    },
    {
      name: "Marilin Urdaneta",
      position: "Senior Project Manager",
      link:
        "https://www.linkedin.com/in/alida-marilin-urdaneta-gonzalez-b00970168/",
      img: "MARILIN_URDANETA.jpg",
    },
    {
      name: "Moira Oliver",
      position: "Production Manager",
      link: "https://www.linkedin.com/in/moiraoliver/",
      img: "Moira_Oliver.png",
    },
    {
      name: "Raquel Sierra",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/raquel-sierra-barrios-467880152/",
      img: "RAQUEL_SIERRA.png",
    },
    {
      name: "Redi Sierra",
      position: "Technical Support",
      link: "https://www.linkedin.com/in/redis23/",
      img: "Redi_Sierra.jpg",
    },
    {
      name: "Richard Sanz",
      position: "Account Executive",
      link: "https://www.linkedin.com/in/rsanze/",
      img: "Richard_Sanz.png",
    },
    {
      name: "Samuel Rodriguez",
      position: "Technical Support Lead",
      link: "https://www.linkedin.com/in/samuel-rodriguez-505a52104/",
      img: "SAMUEL_RODRIGUEZ.jpg",
    },
    {
      name: "Sonia Rodriguez",
      position: "3D Artist",
      link: "https://www.linkedin.com/in/sonyi-rodriguez-89a63473/",
      img: "SONIA_RODRIGUEZ.jpg",
    },
    {
      name: "Val Dess",
      position: "Founder & CEO",
      link: "https://www.linkedin.com/in/valeridessiatnitchenko/",
      img: "val.jpg",
    },
    {
      name: "Viviana Riveros",
      position: "Account Executive",
      link: "https://www.linkedin.com/in/viviana-riveros-mu%C3%B1oz-25ba6651/",
      img: "Viviana_Riveros.png",
    },
    {
      name: "Yuliana Uzcategui",
      position: "Marketing",
      link: "https://www.linkedin.com/in/yulianauzcategui/",
      img: "YULIANA_UZCATEGUI.png",
    },
  ];
  return (
    <section className="team">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title text-center">Our Team</h3>
          </div>
        </div>
        <div className="row pt-5">
          {teams.map((team, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 mb-5" key={index}>
              <Team team={team} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Teams;
