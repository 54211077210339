import React from "react";
import Logos from "../components/Logos";

import { Helmet } from "react-helmet";

class Pricing extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Pricing Policy | hauzd</title>
          <meta
            name="description"
            content="Bring Real Estate Projects to life using hauzd 3D virtual tour presentation. Request a Demo, choose a pricing plan, and connect it with your CRM."
          />
        </Helmet>
        <section className="hauzd-projects-pricing">
          <div className="container">
            <div className="main-titel">
              <h1>Bring Real Estate Projects to life</h1>
              <p>
                hauzd is changing the way that New Real Estate properties
                <br />
                are sold by making Interactive 3D Sales Solutions.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="block-title">How it works</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-5">
                <div className="projects-pricing h-100">
                  <div className="pricing-icon-img">
                    <div className="pricing-icon-1">
                      <img
                        src={require("../assets/images/general-icons-boost.png")}
                        className=""
                        alt="Dan Burdman"
                      />
                    </div>
                    <span className="count-badge">1</span>
                  </div>
                  <div className="pricing-info">
                    <p>
                      Send us your project information (Brochure / CADs /
                      Revit).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5">
                <div className="projects-pricing h-100">
                  <div className="pricing-icon-img">
                    <div className="pricing-icon-2">
                      <img
                        src={require("../assets/images/general-icon-client-interaction.png")}
                        className=""
                        alt="Dan Burdman"
                      />
                    </div>
                    <span className="count-badge">2</span>
                  </div>
                  <div className="pricing-info">
                    <p>Get a customized quote for the project production*</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5">
                <div className="projects-pricing h-100">
                  <div className="pricing-icon-img">
                    <div className="pricing-icon-3">
                      <img
                        src={require("../assets/images/general-icons-marketing.png")}
                        className=""
                        alt="Dan Burdman"
                      />
                    </div>
                    <span className="count-badge">3</span>
                  </div>
                  <div className="pricing-info">
                    <p>Get your 3D Presentation link for all platforms </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5">
                <div className="projects-pricing h-100">
                  <div className="pricing-icon-img">
                    <div className="pricing-icon-4">
                      <img
                        src={require("../assets/images/general-icons-share.png")}
                        className=""
                        alt="Dan Burdman"
                      />
                    </div>
                    <span className="count-badge">4</span>
                  </div>
                  <div className="pricing-info">
                    <p>
                      Share your Link with your Sales Team and Buyers on social
                      media, websites and listings.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="what-you-get pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="package-contents">
                  <h2 className="title">What you Get</h2>
                  <p className="description">
                    Every 3D Project is a completely immersive representation of
                    a real estate development. Hauzd interactive solution helps
                    sales team to boost the sales process and centralize all the
                    marketing in one place.
                  </p>
                  <p className="description">
                    With your 3D Presentation, you have the ability to update
                    price and availability information as well as connect it
                    with your CRM.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="package-infographic">
                  <img
                    src={require("../assets/images/get-image.png")}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="frequently-asked-questions">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12 text-center">
                <a
                  href="https://APP.HAUZD.COM/realestate"
                  target="_blank"
                  className="theme-button"
                >
                  Request a Demo
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="frequently-title">
                  <h3>Frequently Asked Questions</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="faq-item">
                  <h3 className="questions-title">
                    How long does it take to create a typical project?
                  </h3>
                  <p>
                    A typical project (1 tower with 5-10 types of floorplans)
                    can take up to 5-7 weeks.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="faq-item">
                  <h3 className="questions-title">
                    Is the 3D Presentation compatible with all devices?
                  </h3>
                  <p>
                    hauzd provides a unique link that Works for all platforms,
                    all devices (iOS, Android, Windows, Web).
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="faq-item">
                  <h3 className="questions-title">
                    Can I use it on my own website?
                  </h3>
                  <p>
                    You can embed the 3D Presentation on your site as easily as
                    you embed a YouTube video.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Logos />
      </>
    );
  }
}

export default Pricing;
