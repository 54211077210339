import React from "react";
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";

import logo from "../assets/images/logo.png";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavCollapsed: true,
      isPricingDropdownVisible: false,
      isResourcesDropdownVisible: false,
    };

    this.refResource = React.createRef();
    this.refPricing = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    document.addEventListener("click", this.handleClickOutsidePricing, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
    document.removeEventListener("click", this.handleClickOutsidePricing, true);
  }

  // hamburger menu
  handleNavCollapse = () =>
    this.setState({ isNavCollapsed: !this.state.isNavCollapsed });

  // handle dropdown menus
  handlePricingDropdownVisible = () =>
    this.setState({
      isPricingDropdownVisible: !this.state.isPricingDropdownVisible,
      isResourcesDropdownVisible: false,
    });

  // handle dropdown menus
  handleAboutDropdownVisible = () =>
    this.setState({
      isAboutDropdownVisible: !this.state.isAboutDropdownVisible,
      isResourcesDropdownVisible: false,
    });

  handleResourcesDropdownVisible = () =>
    this.setState({
      isPricingDropdownVisible: false,
      isAboutDropdownVisible: false,
      isResourcesDropdownVisible: !this.state.isResourcesDropdownVisible,
    });

  // hide opened dropdown menus if clicked outside
  handleClickOutside = (event) => {
    if (
      this.refResource.current &&
      !this.refResource.current.contains(event.target)
    ) {
      this.setState({
        isResourcesDropdownVisible: false,
      });
    }
  };

  handleClickOutsidePricing = (event) => {
    if (
      this.refPricing.current &&
      !this.refPricing.current.contains(event.target)
    ) {
      this.setState({
        isPricingDropdownVisible: false,
      });
    }
  };

  render() {
    return (
      <header className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="logo-left">
                <Link to="/">
                  <img src={logo} width="150px" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="right-menu">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <Link to="/" className="mobile-logo navbar-brand">
                    <img src={logo} width="150px" />
                  </Link>
                  <button
                    className="custom-toggler navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={!this.state.isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={this.handleNavCollapse}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`${
                      this.state.isNavCollapsed ? "collapse" : ""
                    } navbar-collapse`}
                    id="navbarNav"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <NavLink
                          exact={true}
                          to="/"
                          className="nav-link"
                          activeClassName="active"
                        >
                          For Real Estate
                        </NavLink>
                      </li>
                      <li
                        className={`${
                          this.state.isResourcesDropdownVisible ? "show" : ""
                        } nav-item dropdown`}
                        ref={this.refResource}
                        onMouseEnter={this.handleResourcesDropdownVisible}
                        onMouseLeave={this.handleResourcesDropdownVisible}
                      >
                        <a
                          href="javascript:void(0)"
                          className="nav-link dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded={
                            !this.state.isResourcesDropdownVisible
                              ? true
                              : false
                          }
                        >
                          Resources
                        </a>
                        <div
                          className={`${
                            this.state.isResourcesDropdownVisible ? "show" : ""
                          } dropdown-menu`}
                        >
                        
                          <NavLink
                            to="/gallery"
                            className="dropdown-item"
                            onClick={this.handleResourcesDropdownVisible}
                            activeClassName="active"
                          >
                            3D Gallery
                          </NavLink>

                          <a
                            href="https://learn.hauzd.com/"
                            className="dropdown-item"
                            onClick={this.handleResourcesDropdownVisible}
                          >
                            Support
                          </a>

                          <a
                            href="https://www.hauzd.com/blog/"
                            className="dropdown-item"
                            onClick={this.handleResourcesDropdownVisible}
                          >
                            Blog
                          </a>
                          <a
                            href="https://app.hauzd.com/integrations/"
                            className="dropdown-item"
                            onClick={this.handleResourcesDropdownVisible}
                          >
                            Integrations
                          </a>
                        </div>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/pricing"
                          className="nav-link"
                          activeClassName="active"
                        >
                          Pricing
                        </NavLink>
                      </li>
                      <li
                        className={`${
                          this.state.isAboutDropdownVisible ? "show" : ""
                        } nav-item dropdown`}
                        ref={this.refResource}
                        onMouseEnter={this.handleAboutDropdownVisible}
                        onMouseLeave={this.handleAboutDropdownVisible}
                      >
                        <a
                          href="javascript:void(0)"
                          className="nav-link dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded={
                            !this.state.isAboutDropdownVisible
                              ? true
                              : false
                          }
                        >
                          About
                        </a>
                        <div
                          className={`${
                            this.state.isAboutDropdownVisible ? "show" : ""
                          } dropdown-menu`}
                        >
                        
                          <NavLink
                            to="/about-us"
                            className="dropdown-item"
                            onClick={this.handleAboutDropdownVisible}
                            activeClassName="active"
                          >
                            About us
                          </NavLink>

                          <a
                            href="https://jobs.hauzd.com/"
                            className="dropdown-item"
                            onClick={this.handleAboutDropdownVisible}
                            activeClassName="active"
                            target="_blank"
                          >
                            Careers
                          </a>
                        </div>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/contact-us"
                          className="nav-link"
                          activeClassName="active"
                        >
                          Contact
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <a title="en"
                          href="https://hauzd.com/en/"
                          className="nav-link"
                        ><img src={require("../assets/images/flag/en.svg")} width="15" />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a title="es"
                          href="https://hauzd.com/es/"
                          className="nav-link"
                        ><img src={require("../assets/images/flag/es.svg")} width="15" />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href={
                            this.props.location.pathname !== "/archviz"
                              ? `https://app.hauzd.com/realestate`
                              : `https://app.hauzd.com/signup`
                          }
                          className="header-top-button"
                        >
                          Request a Demo
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://admin.hauzd.com/"
                          className="header-top-button-2"
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
