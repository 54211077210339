import React from "react";
import { connect } from "react-redux";

import Banner3D from "../components/Banner3D";
import IntroCards from "../components/IntroCards";
import Integrations from "../components/Integrations";
import Projects from "../components/Projects";
import HowItWorks from "../components/HowItWorks";
import MobilePlatforms from "../components/MobilePlatforms";
import Testimonials3D from "../components/Testimonials3D";
import Logos from "../components/Logos";

import { fetchProjects } from "../actions/archviz";

import { Helmet } from "react-helmet";

class Archviz extends React.Component {
  componentDidMount() {
    if (!this.props.projects.length > 0) {
      this.props.fetchProjects();
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Interactive Real Estate Sales Tool for 3D Presentation & Archviz | Hauzd
          </title>
          <meta
            name="description"
            content="Create the ultimate 3D sales tool for Real Estate Developers / Brokers / Presentations / Archviz and attract customers and improve your sale. It is EASY. INTUITIVE. FAST."
          />
        </Helmet>
        <Banner3D />
        <IntroCards />
        <Projects projects={this.props.projects} />
        <HowItWorks />
        <MobilePlatforms />
        <Integrations />
        <Testimonials3D />
        <Logos />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.archviz.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Archviz);
