import React from "react";
import { httpClient } from "../helpers/api";
import { ENDPOINTS, API_URL } from "../constants/Constants";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValid: false,
      errorCount: null,
      errors: {
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        country: "",
        message: "",
        what_best_describes_you: "",
      },
      values: {
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        country: "",
        message: "",
        what_best_describes_you: "",
      },
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let values = this.state.values;

    switch (name) {
      case "first_name":
        errors.first_name = !value.length ? "First Name is required!" : "";
        values.first_name = value;
        break;
      case "last_name":
        errors.last_name = !value.length ? "Last Name is required!" : "";
        values.last_name = value;
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        values.email = value;
        break;
      case "company":
        errors.company = !value.length ? "Company is required!" : "";
        values.company = value;
        break;
      case "country":
        errors.country = !value.length ? "Country is required!" : "";
        values.country = value;
        break;
      case "message":
        errors.message = !value.length ? "Message is required!" : "";
        values.message = value;
        break;
      case "what_best_describes_you":
        errors.what_best_describes_you = !value.length
          ? "Please select what best describes you!"
          : "";
        values.what_best_describes_you = value;
        break;
      default:
        break;
    }

    this.setState({ errors });
    this.setState({ values });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });

    // form is valid send the data to the API
    if (validateForm(this.state.errors)) {
      const {
        first_name,
        last_name,
        email,
        company,
        what_best_describes_you,
        country,
        message,
      } = this.state.values;

      // Make api request here.
      var url = API_URL + ENDPOINTS.contact.url;
      var params = `first_name=${first_name}&last_name=${last_name}&email=${email}&company=${company}&type=${what_best_describes_you}&country=${country}&message=${message}`;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);

      //Send the proper header information along with the request
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      xhr.send(params);
    }
  };

  render() {
    const { errors, formValid } = this.state;
    return (
      <div className="contact-form">
        {!formValid && (
          <form method="post" action="" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="first_name" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="First Name"
                    id="first_name"
                    name="first_name"
                    onChange={this.handleChange}
                    required
                  />
                  {errors.first_name.length > 0 && (
                    <span className="error text-danger">
                      {errors.first_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="last_name" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Last Name"
                    id="last_name"
                    name="last_name"
                    onChange={this.handleChange}
                    required
                  />
                  {errors.last_name.length > 0 && (
                    <span className="error text-danger">
                      {errors.last_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-input"
                    placeholder="Email"
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    required
                  />
                  {errors.email.length > 0 && (
                    <span className="error text-danger">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="company" className="form-label">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Company"
                    id="company"
                    name="company"
                    onChange={this.handleChange}
                    required
                  />
                  {errors.company.length > 0 && (
                    <span className="error text-danger">{errors.company}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="what_best_describes_you"
                    className="form-label"
                  >
                    What best describes you?
                  </label>
                  <select
                    name="what_best_describes_you"
                    id="what_best_describes_you"
                    className="form-input"
                    onChange={this.handleChange}
                  >
                    <option value="Architectural Visualization Studio">
                      Architectural Visualization Studio
                    </option>
                    <option value="Real estate developer">
                      Real estate developer
                    </option>
                    <option value="Architect">Architect</option>
                    <option value="Broker/Agent">Broker/Agent</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Country"
                    id="country"
                    name="country"
                    onChange={this.handleChange}
                    required
                  />
                  {errors.country.length > 0 && (
                    <span className="error text-danger">{errors.country}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="Message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-input"
                    placeholder="Message"
                    name="message"
                    id="message"
                    onChange={this.handleChange}
                    required
                  ></textarea>
                  {errors.message.length > 0 && (
                    <span className="error text-danger">{errors.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <button type="submit" className="theme-button">
                  Submit
                  <i className="fa fa-angle-right"></i>
                </button>
                {this.state.errorCount !== null ? (
                  <p className="form-status">
                    Form is {formValid ? "valid ✅" : "invalid ❌"}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}

        {formValid && (
          <p className="alert alert-success">
            Thank you for contacting us, we will get back to you shortly!
          </p>
        )}
      </div>
    );
  }
}

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach((val) => val.length > 0 && (count = count + 1));
  return count;
};

export default ContactForm;
