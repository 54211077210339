import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const MobilePlatforms = (props) => {
  const [isIOSOpen, setIsIOSOpen] = useState(false);
  const [isAndroidOpen, setIsAndroidOpen] = useState(false);
  const [isWindowsOpen, setIsWindowsOpen] = useState(false);
  return (
    <section className="features-3d">
      <span className="bg-gradient bg-rotate-15"></span>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-2 order-sm-2 order-lg-1">
            <div className="banner-thumbnail">
              <img
                src={require("../assets/images/video-thumbnails/ios.png")}
                className="img-fluid"
              />
              <ModalVideo
                channel="youtube"
                isOpen={isIOSOpen}
                videoId="xFQvZXAakCo"
                onClose={() => setIsIOSOpen(false)}
              />
              <a
                onClick={() => setIsIOSOpen(true)}
                href="javascript:void(0);"
                className="banner-link popup-modal"
              >
                See live demo
                <span className="play-icon fa fa-play"></span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-sm-1 order-lg-2">
            <div className="info-card-2 vertical-align">
              <div className="card-icon">
                <img
                  src={require("../assets/images/homepage/general-icons/general-icons-360-3d.png")}
                />
              </div>
              <h3 className="card-title">Multi-platform publishing</h3>
              <p className="card-description">
                Developing applications to run on multiple devices and platforms
                is not easy because you want to make sure every user has a great
                experience.
              </p>
              <p className="card-description mt-0">
                With hauzd platform your content wil be delivered consistently
                regardless of where your clients are and what they are using to
                access it.
              </p>
              <p className="card-description mt-0">
                We operate on platforms such as iOS, Android, Windows and Web
                (browser).
              </p>
            </div>
            <span className="bg-shape-red-3d"></span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="platform-links">
              <ul>
                <li>
                  <ModalVideo
                    channel="youtube"
                    isOpen={isWindowsOpen}
                    videoId="fN4xtDObMyI"
                    onClose={() => setIsWindowsOpen(false)}
                  />
                  <a
                    onClick={() => setIsWindowsOpen(true)}
                    href="javascript:void(0)"
                    className="platform-link"
                  >
                    <img
                      src={require("../assets/images/homepage/general-icons/windows.png")}
                    />
                  </a>
                </li>
                <li>
                  <ModalVideo
                    channel="youtube"
                    isOpen={isAndroidOpen}
                    videoId="_KoTvkkicCM"
                    onClose={() => setIsAndroidOpen(false)}
                  />
                  <a
                    onClick={() => setIsAndroidOpen(true)}
                    href="javascript:void(0)"
                    className="platform-link"
                  >
                    <img
                      src={require("../assets/images/homepage/general-icons/android.png")}
                    />
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setIsIOSOpen(true)}
                    href="javascript:void(0)"
                    className="platform-link popup-modal"
                  >
                    <img
                      src={require("../assets/images/homepage/general-icons/ios.png")}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="platform-links-description">
              <p>
                <i className="fa fa-arrow-left"></i>
                Click on each platform to learn how
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobilePlatforms;
