import React from "react";
import Logos from "../components/Logos";
import ContactForm from "../components/ContactForm";

import { Helmet } from "react-helmet";

class AboutUs extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us | hauzd</title>
          <meta
            name="description"
            content="If you have any questions or issue you faced in real estate presentation. we are here to help you, Check here to get your answer and contact us."
          />
        </Helmet>
        <section className="contact-us">
          <div className="container">
            <div className="main-titel">
              <h1>Contact Us</h1>
              <p>Lets Get in Touch</p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <div className="call-icon">
                      <img
                        src={require("../assets/images/call.png")}
                        className="img-fluid"
                      />
                    </div>
                    <p className="talk-to-number">
                      Talk to a Sales Rep.
                      <br /> +1 415 800 4357
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-4 col-sm-6">
                    <div className="address-block">
                      <span className="location">San Francisco</span>
                      <p>
                        18 Bartol Street #192 San&nbsp;Francisco, CA 94133{" "}
                        <br />
                        +1 415 800 4357
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-4 col-sm-6">
                    <div className="address-block">
                      <span className="location">Panama</span>
                      <p>
                        City of Knowledge, Bldg 109. Panama City <br />
                        +507 310-0361
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
        <Logos />
      </>
    );
  }
}

export default AboutUs;
