import { httpClient } from "../helpers/api";
import { SET_GALLERY } from "../constants/ActionTypes";
import { ENDPOINTS } from "../constants/Constants";

export const fetchGallery = () => {
  return (dispatch) => {
    httpClient
      .get(ENDPOINTS.gallery.url)
      .then(({ data }) => {
        dispatch(setGallery(data));
      })
      .catch(function (error) {});
  };
};

export const setGallery = (gallery) => ({
  type: SET_GALLERY,
  payload: gallery,
});
