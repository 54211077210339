import React from "react";

const Cookies = (props) => {
  return (
    <>
      <section className="testimonials pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section cookies">
                <h1 className="card-title">Cookies Policy</h1>
                <h3>Last updated: 12/13/2019</h3>
                <p>
                  Hauzd LLC uses cookies on https://hauzd.com. By using our
                  Services, you consent to the use of cookies.
                </p>
                <p>
                  Our Cookies Policy explains what cookies are, how we use
                  cookies, how third-parties we may partner with may use cookies
                  on the Service, your choices regarding cookies and further
                  information about cookies.
                </p>
                <h3>What are cookies</h3>
                <p>
                  Cookies are small pieces of text sent by your web browser by a
                  website you visit. A cookie file is stored in your web browser
                  and allows the Service or a third-party to recognize you and
                  make your next visit easier and the Service more useful to
                  you. Cookies can be "persistent" or "session" cookies.
                </p>
                <h3>How Hauzd LLC uses cookies</h3>
                <p>
                  When you use and access the Service, we may place a number of
                  cookies files in your web browser.
                </p>
                <p>
                  We use cookies for the following purposes: To enable certain
                  functions of the Service, to provide analytics, to store your
                  preferences, to enable advertisements delivery, including
                  behavioral advertising.
                </p>
                <p>
                  We use both session and persistent cookies on the Service and
                  we use different types of cookies to run the Service:
                </p>
                <p>
                  - Essential cookies. We may use essential cookies to
                  authenticate users and prevent fraudulent use of user
                  accounts.
                </p>
                <h3>Third-party cookies</h3>
                <p>
                  In addition to our own cookies, we may also use various
                  third-parties cookies to report usage statistics of the
                  Service, deliver advertisements on and through the Service,
                  and so on.
                </p>
                <p>What are your choices regarding cookies</p>
                <p>
                  If you'd like to delete cookies or instruct your web browser
                  to delete or refuse cookies, please visit the help pages of
                  your web browser.
                </p>
                <p>
                  Please note, however, that if you delete cookies or refuse to
                  accept them, you might not be able to use all of the features
                  we offer, you may not be able to store your preferences, and
                  some of our pages might not display properly.
                </p>
                <h3>Where can you find more information about cookies</h3>
                <p>
                  You can learn more about cookies and the following third-party
                  websites:
                </p>
                <p>AllAboutCookies: http://www.allaboutcookies.org/</p>
                <p>
                  Network Advertising Initiative:
                  http://www.networkadvertising.org/
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cookies;
