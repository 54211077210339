import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PricingCard = (props) => {
  return (
    <div className="col-lg-2 col-md-6  px-md-0">
      <div className="pricing-card mb-5 mb-lg-0">
        <div className="pricing-block">
          <h5 className="title">{props.name}</h5>

          {props.name === "XTRA LARGE" ? (
            <a href="mailto:sales@hauzd.com" className="header-top-button-2">
              Contact us
            </a>
          ) : (
            <h6 className="price">
              <span className="currency">{props.currency}</span>
              {props.price}
            </h6>
          )}
          <h5 className="title">
            {props.oneTimeFee !== "false" ? "ONE TIME FEE" : ""}&nbsp;
          </h5>
        </div>
        <div className="pricing-block">
          <p className="info">
            {props.upto !== "false" ? "Up to " : "More than "}
            {props.units} units
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  one unit is considered one house, Apartment, office, Building,
                  master plan of floorplan.
                </Tooltip>
              }
            >
              <span>(?)</span>
            </OverlayTrigger>
          </p>
        </div>
        {props.button && (
          <div className="pricing-block">
            <a href={props.link} className="action-link">
              {props.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
