/**
 * API Constants
 */

export const API_URL = "https://admin.hauzd.com/api/v1/";
export const ENDPOINTS = {
  projects: {
    url: "gallery/data?type=website",
  },
  gallery: {
    url: "gallery/data?type=website",
  },
  contact: {
    url: "website/contact",
  },
};
