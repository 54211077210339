import React from "react";

const HowItWorks = (props) => {
  const cards = [
    {
      title: "Create a 3D presentation",
      text:
        "Hauzd will help you make 3D presentations for real estate. It’s Easy, Intuitive and Fast.",
      icon: "images/homepage/general-icons/general-icons-touch-screen-3d.png",
    },
    {
      title: "Publish presentation",
      text:
        "Upload your presentation to Hauzd Cloud and it will automatically be ready for all platforms.",
      icon: "images/homepage/general-icons/general-icons-customized-ui-3d.png",
    },
    {
      title: "Share and engage",
      text:
        "Share your 3D Presentation with a simple link for all devices. embed your project in any website or portal.",
      icon:
        "images/homepage/general-icons/general-icons-unlimited-downloads-3d.png",
    },
  ];
  return (
    <section className="features-3d">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title-3d">How it works</h2>
            <h3 className="section-subtitle-3d">
              Hauzd makes the easiest and affordable way to create interactive
              3D Presentations for real estate
            </h3>
            <span className="bg-shape-blue-3d"></span>
          </div>
        </div>
        <div className="row">
          {cards.map((card, index) => (
            <div className="col-md-4" key={index}>
              <div className="feature-card">
                <div className="card-icon">
                  <img src={require(`../assets/${card.icon}`)} />
                </div>
                <h3 className="card-title">{card.title}</h3>
                <p className="card-description">{card.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <a
              href="https://hauzd.com/signup/"
              target="_blank"
              className="theme-button"
            >
              Learn more
              <i className="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
