import React from "react";

const IntroCards = (props) => {
  const cards = [
    {
      title: "No programming",
      text:
        "Concentrate on 3D modelling and design while the software is made by the top engineering team.",
      icon: "images/homepage/general-icons/general-icons-boost-3d.png",
      color: "blue",
    },
    {
      title: "Short production time",
      text:
        "Quick delivery of 3-4 weeks for one artist for a medium project. Iterate content with only two clicks.",
      icon: "images/homepage/general-icons/general-icons-marketing-3d.png",
      color: "aqua",
    },
    {
      title: "Cost-effective",
      text:
        "Affordable software subscription fees and you pay only when publishing your finished project.",
      icon:
        "images/homepage/general-icons/general-icon-client-interaction-3d.png",
      color: "yellow",
    },
  ];
  return (
    <section className="scroll-next-section features-3d">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="intro-cards-3d">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="section-title-3d">
                    Create the ultimate sales tool for Real Estate Developers
                    and Brokers
                  </h2>
                  <span className="bg-circle-1"></span>
                  <span className="bg-circle-2"></span>
                  <span className="bg-plus"></span>
                </div>
              </div>
              <div className="row mt-72">
                {cards.map((card, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="intro-card ic-3d">
                      <div className={`general-icon ${card.color}-icon`}>
                        <img src={require(`../assets/${card.icon}`)} />
                      </div>
                      <h3 className="card-title">{card.title}</h3>
                      <p className="card-content">{card.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroCards;
