import React from "react";

import zapier from "../assets/images/homepage/integrations/zapier.png";
import salesForce from "../assets/images/homepage/integrations/salesforce.png";
import agileCRM from "../assets/images/homepage/integrations/agile-crm.png";
import pipeDrive from "../assets/images/homepage/integrations/pipedrive.png";
import zohoCRM from "../assets/images/homepage/integrations/zoho-crm.png";
import hubspot from "../assets/images/homepage/integrations/hubspot.png";

const Integrations = () => (
  <section className="integrations">
    <div className="container">
      <div className="integration-block">
        <div className="row">
          <div className="col-md-12">
            <h3 className="block-title">
              Learn how <span className="highlight-text">hauzd</span> is
              integrated with hundreds of Applications.
            </h3>
            <p className="block-description">
              We are working every day to make your job as a real estate agent,
              easier, faster and better. This is why we are implemementing CRMs
              integration with our hauzd Apps, so you can sync your app with the
              info you manage in YOUR favorite CRM module.
            </p>
          </div>
        </div>
        <div className="integration-list">
          <div className="row">
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={zapier}
                  className="img-fluid"
                  alt="Zapier Integration"
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={salesForce}
                  className="img-fluid"
                  alt="Salesforce Integration"
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={agileCRM}
                  className="img-fluid"
                  alt="Agile CRM Integration"
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={pipeDrive}
                  className="img-fluid"
                  alt="Pipedrive Integration"
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={zohoCRM}
                  className="img-fluid"
                  alt="Zoho CRM Integration"
                />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-6 my-auto">
              <a href="javascript:void(0)" className="integration-link">
                <img
                  src={hubspot}
                  className="img-fluid"
                  alt="Hubspot Integration"
                />
              </a>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-12 text-center">
              <a
                href="https://APP.HAUZD.COM/realestate"
                target="_blank"
                className="theme-button"
              >
                Learn more
                <i className="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Integrations;
