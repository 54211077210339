import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const Projects = (props) => {
  let modalProject = React.createRef();

  function openProjectModal(projectSlug) {
    if (modalProject.current.attributes[0].nodeName === "href") {
      modalProject.current.attributes[0].nodeValue = `//${projectSlug}`;
      document.querySelector(".hauzd-link").click();
    }
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return <button className="project-next" onClick={onClick}></button>;
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <button className="project-prev" onClick={onClick}></button>;
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "120px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="featured-projects">
      <span className="bg-gradient bg-rotate-345"></span>
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title-3d">
                Real estate projects created by 3D visualization and rendering
                studios
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings} className="project-slider">
                {props.projects &&
                  props.projects
                    .sort((a, b) =>
                      a.website_feature < b.website_feature ? 1 : -1
                    )
                    .slice(0, 5)
                    .map((project) => {
                      let slug = "";
                      if (project.url) {
                        slug = project.url.replace("https://", "");
                        slug = slug.replace(".hauzd.com", "");
                        slug = slug.replace(".hauzd.app", "");
                        slug = slug.replace("http://", "");
                      }
                      return (
                        <div className="item" key={project.id}>
                          <div
                            className="project-image"
                            style={{
                              backgroundImage: `url(${project.image_url})`,
                            }}
                          ></div>
                          <div className="project-details">
                            <h3>
                              <span
                                className={`flag-icon flag-icon-${project.country_code.toLowerCase()}`}
                              ></span>
                              {project.title}
                            </h3>
                            <p>{project.description}</p>
                            {project.url && (
                              <a
                                onClick={() => openProjectModal(slug)}
                                href="javascript:void(0)"
                                className="card-link"
                              >
                                Open Project
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    })}
              </Slider>
              <widget-hauzd href="" className="card-link" ref={modalProject}>
                <span style={{ display: "none" }}>Click</span>
              </widget-hauzd>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <Link to="/gallery" className="theme-button">
                View more projects
                <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
