import React from "react";

const Team = (props) => {
  return (
    <div className="team-block mt-5">
      <div className="team-avatar-img">
        <img
          src={require(`../assets/images/teams/${props.team.img}`)}
          className="team-avatar"
          alt={props.team.name}
        />
      </div>
      <div className="team-info">
        <h5>{props.team.name}</h5>
        <h6>{props.team.position}</h6>
        <a className="linkedin-profile" href={props.team.link} target="_blank">
          <i className="fa fa-linkedin circle-icon"></i>
        </a>
      </div>
    </div>
  );
};

export default Team;
