import React from "react";
import "./board.scss";

const Board = (props) => {
  const boardMembers = [
    {
      name: "Bill Brown",
      about:
        "Bill is an experienced tech executive who has taken multiple technologies from concept through successful launch and growth phases. Most recently, Bill successfully led Matterport's launch and growth as CEO, taking the company from pre-product to a dominant SaaS market position with a substantial revenue within 4 1/2 years of product launch.",
      link: "https://www.linkedin.com/in/billjbrown/",
      img: "Bill_Brown.jpeg",
    },
  ];
  return (
    <section className="board-of-advisors">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title text-center">Board of Advisors</h3>
          </div>
        </div>
      </div>
      <div className="main-block">
        <div className="container">
          <div className="row">
            {boardMembers.map((member, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="advisor-card">
                  <div className="img-block">
                    <img
                      src={require(`../assets/images/board/${member.img}`)}
                      className="img-fluid"
                    />
                  </div>
                  <div className="info-block">
                    <h3>{member.name}</h3>
                    <p>{member.about}</p>
                    {member.link !== "" && (
                      <a
                        className="linkedin-profile"
                        href={member.link}
                        target="_blank"
                      >
                        <i className="fa fa-linkedin circle-icon"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Board;
