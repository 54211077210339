import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Maria de Lourdes Ferrer",
      company: "The-Velopers",
      video: "RFWweclMXN0",
      image: "maria-de-lourdes-ferrer.png",
      review:
        "1 Tower. 170 units. $27M. Sold-out in 1 Week. We’ve used this App to sell our project “Dovle” with 0 printed materials and in record-breaking time. It helped us to accelerate the process: 20 min per buyer.",
    },
    {
      name: "Shavonne",
      company: "Hopestreet",
      video: "Br5got0jFdI",
      image: "Shavonne.png",
      review:
        "One of my favorite functions of this is the amenity space feature because with new construction usually the amenities are the last basic to be done so you can’t actually walk buyers through them…",
    },
    {
      name: "Bryan Kelly",
      company: "Punta Pacifica Realty",
      video: "ml3a8wyx0BY",
      image: "Bryan.png",
      review:
        "I work with hauzd and the app that they’ve created was just absolutely amazing for us here on Ocean Reef. Allows us to do so much for our clients: We’re able to show inventory, things that are close..everything that a sales person need to show to these people…",
    },
  ];

  const [isOpenFirstVideo, setIsOpenFirstVideo] = useState(false);
  const [isOpenSecondVideo, setIsOpenSecondVideo] = useState(false);
  const [isOpenThirdVideo, setIsOpenThirdVideo] = useState(false);

  return (
    <section className="testimonials bg-quote">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="page-title">Our Success Stories</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[0].review}</p>
              <ModalVideo
                channel="youtube"
                isOpen={isOpenFirstVideo}
                videoId={testimonials[0].video}
                onClose={() => setIsOpenFirstVideo(false)}
              />
              <a
                onClick={() => setIsOpenFirstVideo(true)}
                className="card-link"
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[0].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[0].name}</h4>
                  <p className="organisation">{testimonials[0].company}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[1].review}</p>
              <ModalVideo
                channel="youtube"
                isOpen={isOpenSecondVideo}
                videoId={testimonials[1].video}
                onClose={() => setIsOpenSecondVideo(false)}
              />
              <a
                onClick={() => setIsOpenSecondVideo(true)}
                className="card-link"
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[1].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[1].name}</h4>
                  <p className="organisation">{testimonials[1].company}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <p className="card-description">{testimonials[2].review}</p>
              <ModalVideo
                channel="youtube"
                isOpen={isOpenThirdVideo}
                videoId={testimonials[2].video}
                onClose={() => setIsOpenThirdVideo(false)}
              />
              <a
                onClick={() => setIsOpenThirdVideo(true)}
                className="card-link"
              >
                Check video
                <span className="play-icon fa fa-play"></span>
              </a>
              <div className="card-author">
                <div className="customer-image">
                  <img
                    src={require(`../assets/images/tesimonials/${testimonials[2].image}`)}
                  />
                </div>
                <div className="customer-details">
                  <h4 className="name">{testimonials[2].name}</h4>
                  <p className="organisation">{testimonials[2].company}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
