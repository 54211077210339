import React from "react";

const Counters = () => (
  <section className="statistics">
    <div className="container">
      <div className="counter-block">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="block-title">Successfully & counting</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="stat-item">
              <h2 className="value">+300</h2>
              <p className="description">Projects created with Hauzd tech</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stat-item">
              <h2 className="value">+$10b</h2>
              <p className="description">Real estate value</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stat-item">
              <h2 className="value">+15</h2>
              <p className="description">
                Countries where Hauzd tech is being used
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Counters;
