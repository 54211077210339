import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import topBR from "../assets/images/top-br.png";

const Logos = () => {
  const logos = [
    "absalon.png",
    "AR-construcciones.png",
    "cds.png",
    "colliers.png",
    "colpatria.png",
    "concert.png",
    "FWS.png",
    "g&D.png",
    "hopestreet.png",
    "IDEI_LOGO.png",
    "logo-brodyfriedman.png",
    "northbaires.png",
    "Thelanddevelopers.png",
    "the-velopers.png",
    "Tridel.png",

    "albamar.png",
    "arquimia.png",
    "aparedes.png",
    "desarrolladora.png",
    "padova.png",
    "vyv.png",
    "milk.png",
    "gimdev.png",
    "ucalli.png",
    "guia.png",
    "thor.png",
    "gfa.png",
  ];

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return <button className="slick-next" onClick={onClick}></button>;
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <button className="slick-prev" onClick={onClick}></button>;
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <section className="logo-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="over-companies">
              <div className="text-center">
                <img src={topBR} />
                <h4>
                  Trusted Over <span className="highlight">300+</span> Companies
                </h4>
                <h2>You are not alone.</h2>
                <p>
                  Many real estate developers from all over the world are using{" "}
                  <strong>hauzd</strong> tech to boost their sales process.
                </p>
              </div>
            </div>
            <Slider {...settings} className="logo-slider">
              {logos.map((logo, index) => (
                <div className="item maxheight" key={index}>
                  <img
                    src={require(`../assets/images/logos/${logo}`)}
                    className="img-fluid maxheight"
                    alt="logo"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Logos;
