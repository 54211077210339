/*import React from "react";

const Awards = (props) => {
  return (
    <section className="award-winning pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-3">
            <h2 className="award-title">Award-winning solution</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7">
            <div className="row">
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-1.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-2.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-3.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-4.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-5.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-6.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-3 col-6 mt-3">
                <img
                  src={require("../assets/images/logo-8.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3 my-auto text-center">
            <img
              src={require("../assets/images/logo-7.png")}
              alt="logo"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;*/


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import topBR from "../assets/images/top-br.png";

const Awards = () => {
  const logos = [
    "logo-1.png",
    "logo-2.png",
    "logo-3.png",
    "logo-4.png",
    "logo-5.png",
    "logo-6.png",
    "logo-7.png",
    "logo-8.png",
  ];

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return <button className="slick-next" onClick={onClick}></button>;
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <button className="slick-prev" onClick={onClick}></button>;
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <section className="logo-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="over-companies">
              <div className="col-md-12 text-center mb-3">
                <h2 className="award-title">Award-winning solution</h2>
              </div>
            </div>
            <Slider {...settings} className="logo-slider">
              {logos.map((logo, index) => (
                <div className="item" key={index}>
                  <img
                    src={require(`../assets/images/${logo}`)}
                    className="img-fluid"
                    alt="logo"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;