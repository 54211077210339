import { httpClient } from "../helpers/api";
import { SET_PROJECTS } from "../constants/ActionTypes";
import { ENDPOINTS } from "../constants/Constants";

export const fetchProjects = () => {
  return (dispatch) => {
    httpClient
      .get(ENDPOINTS.projects.url)
      .then(({ data }) => {
        dispatch(setProjects(data));
      })
      .catch(function (error) {});
  };
};

export const setProjects = (properties) => ({
  type: SET_PROJECTS,
  payload: properties,
});
