import React from "react";
const Project = (props) => {
  let slug = "";
  if (props.project.url) {
    slug = props.project.url.replace("https://", "");
    slug = slug.replace(".hauzd.com", "");
    slug = slug.replace(".hauzd.app", "");
    slug = slug.replace("http://", "");
  }

  return (
    <div className="gallery-item">
      <div
        className="property-image"
        style={{ backgroundImage: `url(${props.project.image_url})` }}
      ></div>
      <div className="property-text">
        <h6>
          <span
            className={`flag-icon flag-icon-${props.project.country_code.toLowerCase()}`}
          ></span>
          {props.project.title}
        </h6>
        <p>{props.project.description}</p>
        {props.project.website_feature === 1 && (
          <widget-hauzd href={`//${slug}`} className="card-link">
            <span>Open Project</span>
          </widget-hauzd>
        )}
      </div>
    </div>
  );
};

export default Project;
