import React from "react";
import AboutBanner from "../components/AboutBanner";
import Teams from "../components/Teams";
import Board from "../components/Board";
import Awards from "../components/Awards";
import Logos from "../components/Logos";

import { Helmet } from "react-helmet";

class AboutUs extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>About us | hauzd</title>
          <meta
            name="description"
            content="Buying a house, an apartment or making an investment on a new office is one of the most important decisions. At Hauzd, we want buyers to understand their new property."
          />
        </Helmet>
        <AboutBanner />
        <Teams />
        <Board />
        <Awards />
        <Logos />
      </>
    );
  }
}

export default AboutUs;
