import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";

const handleScroll = () => {};

const AboutBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  let nextSection = React.createRef();

  function handleScroll() {
    nextSection.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <h2 className="title">
                  Changing the property-buying experience
                </h2>
                <p className="tag">
                  Buying a house, an apartment or making an investment on a new
                  office is one of the most important decisions. <br />
                  At Hauzd, we want buyers to understand their new property and
                  fall in love with it long before it’s built. <br />
                  Since 2016 we have been delivering the most dynamic
                  experiences to every user through interactivity using live 3D
                  graphics.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumbnail">
                <img
                  src={require("../assets/images/video-thumbnails/home.png")}
                  className="img-fluid"
                />
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="mlv7GGyebbY"
                  onClose={() => setIsOpen(false)}
                />
                <a
                  onClick={() => setIsOpen(true)}
                  href="javascript:void(0);"
                  className="banner-link popup-modal"
                >
                  See live demo
                  <span className="play-icon fa fa-play"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center scroll-next-block">
          <a
            href="javascript:void(0)"
            className="scroll-next"
            onClick={handleScroll}
          >
            <i className="fa fa-angle-down"></i>
          </a>
        </div>
      </section>
      <section ref={nextSection}></section>
    </>
  );
};

export default AboutBanner;
