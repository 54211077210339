import React from "react";
import Logos from "../components/Logos";
import PricingCard from "../components/PricingCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Helmet } from "react-helmet";

class PricingArchviz extends React.Component {
  constructor(props) {
    super(props);

    this.nextSection = React.createRef();
  }

  handleScroll = () => {
    this.nextSection.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Pricing for Archviz professionals| hauzd engine</title>
          <meta
            name="description"
            content="Choose a Pricing Plan for Archviz professionals, that fits with your new real estate project. Simple pricing with no setup fees, per project."
          />
        </Helmet>
        <section className="hauzd-projects-pricing">
          <div className="container">
            <div className="main-titel text-center">
              <h2>
                Simple pricing with no setup fees, <u>per project</u>
              </h2>
              <p>5 Year subscription to cover your entire sales cycle</p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="pricing-heading">
                  Choose a Plan that fits with your new real estate project
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        only valid for new developments. if it's a rental
                        project. please contact us at sales@hauzd.com
                      </Tooltip>
                    }
                  >
                    <span>(?)</span>
                  </OverlayTrigger>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <label className="most-popular">Interactive 2D project</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pricing-block-3d">
                  <div className="row justify-content-center">
                    <PricingCard
                      name="XTRA SMALL"
                      currency="$"
                      price="195"
                      oneTimeFee="true"
                      units="50"
                      upto="true"
                      link=""
                      buttonText="Select"
                      button={false}
                    />

                    <PricingCard
                      name="SMALL"
                      currency="$"
                      price="595"
                      oneTimeFee="true"
                      units="100"
                      upto="true"
                      link=""
                      buttonText="Select"
                      button={false}
                    />

                    <PricingCard
                      name="MEDIUM"
                      currency="$"
                      price="995"
                      oneTimeFee="true"
                      units="500"
                      upto="true"
                      link=""
                      buttonText="Select"
                      button={false}
                    />

                    <PricingCard
                      name="LARGE"
                      currency="$"
                      price="1495"
                      oneTimeFee="true"
                      units="1500"
                      upto="true"
                      link=""
                      buttonText="Select"
                      button={false}
                    />

                    <PricingCard
                      name="XTRA LARGE"
                      currency="&nbsp;"
                      price="&nbsp;"
                      oneTimeFee="false"
                      units="1500"
                      upto="false"
                      link=""
                      buttonText="Contact Us"
                      button={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="interactive-app">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="interactive-3d">
                  <p>Upgrade project to use 3D Assets</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="row justify-content-center">
                    <div className="col-lg-2 col-md-6  px-md-0">
                      <div className="pricing-card mb-5 mb-lg-0">
                        <div className="pricing-block">
                          <h6 className="price">
                            <strong className="mr-2 plus-pricing-icon">
                              +
                            </strong>
                            <span className="currency">$</span>
                            295
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 px-md-0">
                      <div className="pricing-card mb-5 mb-lg-0">
                        <div className="pricing-block">
                          <h6 className="price">
                            <strong className="mr-2 plus-pricing-icon">
                              +
                            </strong>
                            <span className="currency">$</span>
                            495
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 px-md-0">
                      <div className="pricing-card mb-5 mb-lg-0">
                        <div className="pricing-block">
                          <h6 className="price">
                            <strong className="mr-2 plus-pricing-icon">
                              +
                            </strong>
                            <span className="currency">$</span>
                            795
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 px-md-0">
                      <div className="pricing-card mb-5 mb-lg-0">
                        <div className="pricing-block">
                          <h6 className="price">
                            <strong className="mr-2 plus-pricing-icon">
                              +
                            </strong>
                            <span className="currency">$</span>
                            995
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 px-md-0">
                      <div className="pricing-card mb-5 mb-lg-0">
                        <div className="pricing-block">
                          <h5 className="title">XTRA LARGE</h5>
                          <a
                            href="mailto:sales@hauzd.com"
                            className="header-top-button-2"
                          >
                            Contact us
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="interactive-3d text-center mt-5">
                  <a
                    href="https://app.hauzd.com/signup"
                    className="theme-button"
                  >
                    REGISTER HERE
                    <i className="fa fa-angle-right"></i>
                  </a>
                </div>
                <div className="interactive-info">
                  <p>Prices shown in U.S. Dollars.</p>
                </div>
              </div>
            </div>
            <div className="row" ref={this.nextSection}>
              <div className="col-md-12">
                <div className="interactive-3d mt-5">
                  <p>What you get</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-4">
                <ul className="list-unstyled lead">
                  <li>
                    <span className="text-success">&#10004;</span>{" "}
                    Multi-platform publishing (iOS, Android, Windows, Web)
                  </li>
                  <li>
                    <span className="text-success">&#10004;</span> Unlimited
                    downloads / users
                  </li>
                  <li>
                    <span className="text-success">&#10004;</span> Technical
                    Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Logos />
      </>
    );
  }
}

export default PricingArchviz;
