import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import thumbnail from "../assets/images/video-thumbnails/home.png";

const Banner = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  let nextSection = React.createRef();

  function handleScroll() {
    nextSection.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <h1 className="title">
                  Bring your real estate projects to life
                </h1>
                <p className="tag">
                  Boost the Sales Process, Centralize your Marketing and Engage Buyers through hauzd's Interactive 3D Digital Platform.
                </p>
                <a
                  href="https://APP.HAUZD.COM/realestate"
                  target="_blank"
                  className="theme-button"
                >
                  Request a Demo
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumbnail">
                <img src={thumbnail} className="img-fluid" />
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="mlv7GGyebbY"
                  onClose={() => setIsOpen(false)}
                />
                <a
                  onClick={() => setIsOpen(true)}
                  href="javascript:void(0);"
                  className="banner-link popup-modal"
                >
                  See live demo
                  <span className="play-icon fa fa-play"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center scroll-next-block">
          <a
            href="javascript:void(0)"
            className="scroll-next"
            onClick={handleScroll}
          >
            <i className="fa fa-angle-down"></i>
          </a>
        </div>
      </section>
      <section ref={nextSection}></section>
    </>
  );
};

export default Banner;
