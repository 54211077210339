import { SET_GALLERY } from "../constants/ActionTypes";

const initialState = {
  gallery: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GALLERY: {
      return {
        ...state,
        gallery: action.payload,
      };
    }
    default:
      return state;
  }
}
