import React, { useRef, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const Banner3D = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  let nextSection = React.createRef();

  function handleScroll() {
    nextSection.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <>
      <section className="banner-section-3d">
        <span className="bg-rectangle-border"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <h3 className="heading">HAUZD ENGINE FOR 3D ARCHVIZ</h3>
                <h1 className="title">
                  The Easiest way to create interactive Sales Tool for real
                  estate.
                </h1>
                <p className="tag">EASY. INTUITIVE. FAST.</p>
                <a
                  href="https://app.hauzd.com/signup"
                  target="_blank"
                  className="theme-button"
                >
                  Request a Demo
                  <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-thumbnail">
                <img
                  src={require("../assets/images/video-thumbnails/home-3d.png")}
                  className="img-fluid"
                />
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="Zrg9DtHRYqU"
                  onClose={() => setIsOpen(false)}
                />
                <a
                  onClick={() => setIsOpen(true)}
                  href="javascript:void(0);"
                  className="banner-link popup-modal"
                >
                  See live demo
                  <span className="play-icon fa fa-play"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center scroll-next-block">
          <a
            href="javascript:void(0)"
            className="scroll-next"
            onClick={handleScroll}
          >
            <i className="fa fa-angle-down"></i>
          </a>
        </div>
      </section>
      <section ref={nextSection}></section>
    </>
  );
};

export default Banner3D;
